import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { fetchLatestSnapshotByDeviceId } from "../../services/cameras.service"

function Component(props){
    const [data,setData]=useState(null)
    const fetchPhoto=async()=>{
        let result = await fetchLatestSnapshotByDeviceId(props.token?.token,props.control?.projectId,props.deviceId)
        if(result?.result?.[0]?.data){
            console.log(result?.result?.[0])
            setData(result?.result?.[0])
        }
    }
    useEffect(()=>{
        if(props.deviceId)
        fetchPhoto()
    },[props.deviceId])

    const closePopup=()=>{if(props.close)props.close()}

    return(
        <div className='fixed inset-0 flex justify-center items-center' style={{backdropFilter:'blur(5px)',zIndex:10}}>
            <div className='p-[24px] bg-[#363636B2] rounded-[8px] flex flex-col text-white relative w-[95%] h-[95%] flex items-center justify-center' style={{border:'.5px solid #FFFFFF80'}}>
                
                <div className='absolute top-[24px] right-[24px] text-[36px] font-bold ml-20 cursor-pointer select-none' onClick={closePopup}>X</div>  

                 {data?
                    <div className="w-full h-full cursor-pointer">
                        <img src={data?.data?.s3url||`data:image/jpg;base64,${data?.data?.base64_data}`} style={{objectFit:'contain',height:'100%'}} className="w-full"/>
                    </div>
                    :<div className="text-white text-[20px]">No Snapshot</div>
                }


            </div>
        </div>
    )
}

export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  })(Component)