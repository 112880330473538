
import './Tracking.css'
import React, { useEffect, useReducer, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from 'react-redux';
import {createPortal} from 'react-dom'
import QRCode from 'qrcode'
import { LEFTRIGHT_PADDING } from "../config/style.config";
import { server_ip } from '../config';

import ReactToPrint from 'react-to-print';

//svg
import TitleLeftSvg from '../svgs/TitleLeftSvg'
import SmallHeader from '../svgs/tracking/SmallHeader'
import PenSvg from '../svgs/tracking/PenSvg'
import RedBinSvg from '../svgs/tracking/RedBinSvg'
import HistorySvg from '../svgs/tracking/HistorySvg'
import EyeSvg from '../svgs/tracking/EyeSvg'
import DownloadSvg from '../svgs/tracking/DownloadSvg'
import DocSvg from '../svgs/tracking/DocSvg'
import BlueDownloadSvg from '../svgs/tracking/BlueDownloadSvg'
import PrintSvg from '../svgs/tracking/PrintSvg'
import { Ticks } from 'chart.js';
import WhiteCloseSvg from '../svgs/WhiteCloseSvg'
//API
import {fetchTrackingDataById, postDocumentUploadWithId, deleteTrackingRecordsById} from '../services/httpApiTracking'
//action
import { getDateOnly, validateItemWithDate} from '../actions/actionsDateTime';
//componets
import DetailHistory from '../components/tracking/DetailHistory';
import DocumentList from '../components/tracking/DocumentList';
import EditPopUp from '../components/tracking/EditPopUp';
import PopUpBase from '../components/PopUpBase';
import Loading from '../components/Loading';

const qrOptions = {
    scale: 8, 
    color: {
        dark: '#000',  // Blue dots
        light: '#FFFFFF' // Transparent background
    }
}
function Screen(props){
    const printRef=useRef(null)
    const hasPerms = props.control.permissions
    const [isUploading,setisUploading]=useState(false)
    const navigate = useNavigate();
    const param = useParams()
    const qrRef = useRef(null)
    const [newestHistory, setNewestHistory] = useState(true)
    const [editPopUp, setEditPopUp] = useState(false)
    const [editData, setEditData] = useState(undefined)
    const [deletePopUp, setDeletePopUp] = useState(false)
    const [machineID, setMachineID] = useState(param._id)
    const [{equipmentId, category, certificateStartDate, certificateEndDate, maintenanceDate, history, userDict, documents, _id}, setDetailData] = useState({})
    useEffect(() => {
        genQR(window.location.href);
        if(machineID) fetchData()
    },[machineID,hasPerms])

    async function fetchData(){
        const data = await fetchTrackingDataById(machineID, {}, {})
        if(data?.errno) return console.log('error fetching tracking record')
        if(data.result)setDetailData(data.result)
        if(data.result)setEditData(data.result)
    }
    async function genQR(url){
        QRCode.toCanvas(qrRef.current,url  , qrOptions, function (error) {
            if (error) console.error(error)
          })
    }
    function handleEditPopUp(bol){
        setEditPopUp(bol)
    }
    function handleDeletePopUp(bol){
        setDeletePopUp(bol)
    }
    async function handleFileUpload(e){
        setisUploading(true)
        const res = await postDocumentUploadWithId(props.token.token, _id, e.target.files[0])
        if(res.errno) return alert('Error uploading file')
        setisUploading(false)
        fetchData()
        // console.log(res)
    }
    function downloadQR(){
        let url = qrRef?.current.toDataURL("image/png")||`${server_ip}`;
        let link = document.createElement('a');
        link.download = equipmentId + '-qrCode.png';
        link.href = url;
        link.click();
    }
    async function handleDeleteFile(){
        const res = await deleteTrackingRecordsById(props.token.token, _id)
        if(res.errno) return alert('Error deleting details')
        navigate('/tracking')
    }
    const handleDownloadAll=()=>{
        for(let doc of documents){
            window.open(`${server_ip}/api/documents/${doc.filename}`)
        }
    }
    const handlePrintQR=()=>{

    }
    return(
        <div className='w-full h-full bg-[#0C0D14] flex flex flex-col relative overflow-auto text-white'>
            {editPopUp && createPortal(<PopUpBase closePopUp={()=>{}}> <EditPopUp closePopUp={handleEditPopUp} data={editData} fetchData={fetchData}/> </PopUpBase >, props.portalRef.current)}
            {deletePopUp && createPortal(<PopUpBase closePopUp={handleDeletePopUp}> 
                <div className="w-[25%] min-w-[460px] border border-[#FFFFFF80] rounded bg-[#363636B2] p-[40px] text-white" style={{zIndex:99}}>
                    <div className="flex flex-row justify-between mb-8">
                        <div className="text-[18px] font-bold">Confirm Delete </div>
                        <WhiteCloseSvg className='cursor-pointer' onClick={() => {setDeletePopUp(false)}}/>
                    </div>
                    <div>
                    <div className="font-bold mb-8 text-[18px]">Delete: {equipmentId} ?</div>
                    <div className="flex flex-row items-center w-full gap-4">
                        <div className='flex-1 bg-[#00202A] py-[10px] px-[16px] rounded-full text-[#63BDFF] cursor-pointer select-none text-center' onClick={() => setDeletePopUp(false)}>Cancel</div>
                        <div className='flex-1 bg-[#f5707040] py-[10px] px-[16px] rounded-full text-[#f57070] cursor-pointer select-none text-center' onClick={() => handleDeleteFile()}>Delete</div>
                    </div>

                    </div>
                </div>
            
             </PopUpBase >, props.portalRef?.current)}
            <div style={{...LEFTRIGHT_PADDING}} className='pb-64'>
                <div className="flex flex-row mt-[40px] items-baseline">
                    <TitleLeftSvg className='mr-[9px]'/>
                    <div className="text-[24px] text-white font-bold cursor-pointer select-none" onClick={()=>{navigate('/tracking')}}>Tracking</div>
                    <SmallHeader className='mx-[9px]' style={{transform:'scale(0.6)'}}/>
                    <div className="text-[16px] text-white font-bold ">{category} {equipmentId}</div>
                </div>
                <div className='mt-[40px] flex flex-row flex-wrap'>
                    <div className='flex-1 min-w-[60%]'>
                        <div className='flex flex-row items-center justify-between'>
                            <div className='flex flex-row items-center gap-2'>
                                <div className='text-white'>Detail of Record:</div>
                                <div>{validateItemWithDate(certificateStartDate, certificateEndDate) ? <div className='w-[17px] h-[17px] rounded-full bg-[#29FFD9]'/> : <div className='w-[17px] h-[17px] rounded-full bg-[#f47070]'/> }</div>
                                <div className='text-white'>{equipmentId}</div>
                            </div>
                            {props.token.isLogin && <div className='flex flex-row items-center gap-2 select-none'>
                                {hasPerms.TRACKING_EDIT && <div className='button_blue' onClick={() => handleEditPopUp(true)}><PenSvg className='mr-[4px]'/>Edit</div>}
                                {hasPerms.TRACKING_DELETE && <div className='text-[#F47070] py-[2px] px-[16px] bg-[#F4707033] rounded-full flex flex-row items-center cursor-pointer hover:scale-105' onClick={() => {handleDeletePopUp(true)}}><RedBinSvg className='mr-[4px]'/>Delete</div>}
                            </div>}
                        </div>
                        <div className='mt-[40px] flex flex-row items-center'>
                            {[['Category',category],
                                ['Equipment ID',equipmentId],
                                ['Certificate Date', getDateOnly(certificateStartDate) + ' to ' + getDateOnly(certificateEndDate)],
                                ['Checking & Maintainance', getDateOnly(maintenanceDate)]].map((arr, i) => (
                                    <div key={arr[0] + i} className='' style={{flex: 1}}>
                                        <div className='text-[#A1A1A1]'>{arr[0]}</div>
                                        <div className='text-white' style={{color: arr[0] === 'Checking & Maintainance' ? '#FFC329' : 'white'}}>{arr[1]}</div>
                                    </div>
                                ))
                            }
                        </div>
                        <div className='mt-[40px]'>
                            <div className='flex flex-row items-center justify-between'>
                                <div className='flex flex-row items-center gap-2'>
                                    <div className='text-white font-bold flex flex-row items-center'>History <HistorySvg className='ml-[12px] cursor-pointer hover:scale-110' onClick={() => {setNewestHistory(!newestHistory)}}/><span className='ml-2'>{newestHistory ? 'Newest to Oldest' : 'Oldest to Newest'}</span></div>
                                    
                                </div>
                                <div className='flex flex-row items-center gap-2 select-none cursor-pointer'>
                                    {/* <div className='text-[#FFFFFF] py-[9px] px-[16px] bg-[#00202A] rounded-full flex flex-row items-center hover:scale-105'>
                                        <EyeSvg />
                                        <div className='ml-[9px]'>View All</div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <DetailHistory history={history} userDict={userDict} reverse={newestHistory} />
                        <div className='mt-[48px] text-white'>
                            <div className='flex flex-row items-center justify-between'>
                                <div className='font-bold'>Documents<span className='text-[#A1A1A1] ml-[4px]'>{documents?.length + ' Files'}</span></div>
                                <div className='flex flex-row gap-2'>
                                    <div className="flex flex-col items-center justify-center">
                                        <div className=''>
                                            <label htmlFor="file" className='flex flex-col'>
                                                <p className='w-full text-white text-center rounded-full cursor-pointer hover:scale-105 py-[2px] px-[16px] select-none' style={{borderWidth:1, borderColor:'#FFFFFF80'}}>Upload File</p>
                                            </label>
                                            <input type="file" id="file" name="file" onChange={(e) => {handleFileUpload(e)}} value={''} className='w-full' style={{display:'none'}}/>
                                        </div>
                                    </div>
                                    <div onClick={handleDownloadAll} className='bg-[#00202A] py-[2px] px-[16px] rounded-full flex flex-row items-center select-none cursor-pointer hover:scale-105'><DownloadSvg className='mr-[8px]'/>Download All</div>
                                </div>
                            </div>
                            <div className='mt-[24px]'>
                                {documents?.map((obj, i) => <DocumentList key={'DOC' + i} docObj={obj} fetchData={fetchData} userDict={userDict} popRef={props.portalRef?.current}/>)}
                            </div>
                        </div>
                    </div>
                    <div className='w-[670px] max-w-[100%] text-white flex flex-col items-center flex-1 mt-12'>
                        <div className='text-[32px] font-bold'>Scan QR Code</div>
                        <div className='text-[14px] text-[#A1A1A1]'>Scan this QR Code to view detail of record on mobile phone</div>
                        {/* <img src='/QRCode.png' alt='QRCode' style={{height:undefined,aspectRatio:264/264, resize:'cover'}}/> */}
                        <div className='flex flex-col bg-white text-black items-center font-bold' ref={printRef}>
                            <canvas ref={qrRef}/>
                            <div className='mt-[-8px]'>{category}</div>
                            <div>ID: {equipmentId}</div>
                        </div>
                        <div className='w-[331px] mt-[40px] select-none'>
                            <a onClick={downloadQR} className='flex-1 bg-[#00202A] py-[10px] px-[16px] rounded-full flex flex-row items-center justify-center text-[#63BDFF] mb-[16px] cursor-pointer'><BlueDownloadSvg className='mr-[8px]'/>Download QR Code</a>
                            <ReactToPrint
                                trigger={()=><div onClick={handlePrintQR} className=' hover:scale-105 flex-1 py-[10px] px-[16px] rounded-full flex flex-row items-center justify-center border border-white cursor-pointer'><PrintSvg className='mr-[8px]'/>Print</div>}
                                content={()=>printRef.current}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {isUploading&&<Loading/>}
        </div>
    )
}

export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  })(Screen)