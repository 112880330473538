import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import { useNavigate,useSearchParams } from 'react-router-dom';
import SmallHeader from '../../svgs/tracking/SmallHeader'
import AlertVideoPopup from '../camera/AlertVideoPopup'
import AlertSnapshotPopup from "../camera/AlertSnapshotPopup";
import CopyLink from "../CopyLink";
//
import DetailArrowSvg from '../../svgs/summary/DetailArrowSvg'
import AlertVideoSvg from '../../svgs/camera/AlertVideoSvg'
import AlertPhotoSvg from '../../svgs/camera/AlertPhotoSvg'
import BinSvg from '../../svgs/camera/BinSvg'

//
import { handleFilterDate } from "../../actions/actionsDateTime";
import { fetchCameraEvent } from "../../services/cameras.service";
import { deleteWatchAlert } from "../../services/watches.service";
import { server_ip } from "../../config";
import AlertSnapshot from "../camera/AlertSnapshot";
import { fetchCameras } from "../../services/request.service";
const ALERT_TYPE_DICT={
    'CAMERA_DNG_ZONE':'Danger Zone',
    'CAMERA_NO_JACKET':'No Jactket',
    'CAMERA_NO_HELMET':'No Helmet',
    'CAMERA_FALLDOWN':'Falldown'
}
function Component(props){
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();
    const [targetDelete, setTargetDelete] = useState(null)
    const [data,setData]=useState([])
    const filterDate = (searchParams.get('date') || 'Today')
    const filterFrom = searchParams.get('from')
    const filterTo = searchParams.get('to')
    useEffect(() => {
        fetchData()
    },[searchParams])
    const fetchData=async()=>{
        let dangerZoneCameras = await fetchCameras(props.token?.token,{ projectId:props.control?.projectId ,isDangerZone:true})
        let deviceIDs = dangerZoneCameras?.result?.map(c=>c.id)

        const filter={ projectId:props.control?.projectId , deviceID:{$nin:deviceIDs} }
        if(filterDate) filter['time'] = handleFilterDate(filterDate)
        if(filterFrom && filterTo) filter['time'] = handleFilterDate('', filterFrom, filterTo)

        let result = await fetchCameraEvent(props.token.token,filter,{limit:999 , sort:{time:-1}})
        if(result.errno===10001){
            return alert('Session Expired');
        }else if(result?.result){
            setData(result?.result);
        }
    }
    //snapshot popup
    const [isSnapshot,setisSnapshot]=useState(null)
    const handleShowPopup=(item,index)=>()=>{
        setisSnapshot(item)
    }
    const handleClosePopup=()=>{setisSnapshot(null)}
    //

    //video popup
    const [isAlertVideo,setisAlertVideo]=useState(null)
    const handleShowVideoPopup=(item,index)=>()=>{
        setisAlertVideo(item)
    }
    const handleCloseVideoPopup=()=>{setisAlertVideo(null)}
    //end of video popup
    async function handleDelete(){
        const res = await deleteWatchAlert(props.token.token, targetDelete._id)
        if(res.errno) return
        fetchData()
        setTargetDelete(null)
    }
    return(
        <div className="w-full flex flex-col text-white">
                <div className="flex flex-row w-full justify-between items-center pb-[8px]" style={{}}>
                    <div className="text-[16px] font-[300] flex flex-row items-center gap-2"><SmallHeader />Camera Alerts<CopyLink /></div>
                    {props.isReport||<div className='text-[12px] flex flex-row justify-center gap-2 cursor-pointer' onClick={() => {navigate('/camera/alert')}}>Details<DetailArrowSvg /></div>}
                </div>
                <div className="py-[32px] px-[30px]">
                    <div className="flex flex-row text-[10px] font-bold w-full items-center px-[16px] mb-[16px]">
                        <div className="flex-[1]">Item</div>
                        <div className="flex-[2]">Camera Id</div>
                        <div className="flex-[2]">Camera Name</div>
                        <div className="flex-[2]">Date & Time</div>
                        <div className="flex-[2]">Alert Type</div>
                        <div className="flex-[2]">Snapshot</div>
                        {!props.isReport &&<div className="flex-[2]">Alert Video</div>}
                        {props.control?.permissions?.SUMMARY_HIDE_ALERT && !props.isReport &&<div className="w-[100px]"/>}
                    </div>
                    {data.map((item, idx) =>
                        <div key={item?._id||idx} className="text-[14px] flex flex-row py-[16px] px-[16px]" style={{overflowWrap: 'break-word',backgroundColor:idx%2===1?'#FFFFFF1D':'transparent'}}>
                            {/* <div className="flex-[1]">{limit*(page-1)+idx+1}</div> */}
                            <div className="flex flex-1">{idx + 1}</div>
                            <div className="flex-[2]">{item?.deviceID}</div>
                            <div className="flex-[2]">{item?.name}</div>
                            <div className="flex-[2]">{new Date(item?.time).toLocaleString()}</div>
                            <div className="flex-[2]">{ALERT_TYPE_DICT[item?.type]||item?.type}</div>
                            {props.isReport?<div className="flex-[2] flex flex-row items-center justify-start">
                                    <AlertSnapshot isReport={true} index={idx} deviceId={item?.deviceID} time={item?.time}/>
                                </div>
                                :<div className="flex-[2] flex flex-row items-center gap-[4px] cursor-pointer"  onClick={handleShowPopup(item,idx)}>
                                    Snapshot <AlertPhotoSvg/>
                                </div>
                            }
                            {!props.isReport &&<div className="flex-[2] flex flex-row items-center gap-[4px] cursor-pointer" onClick={handleShowVideoPopup(item,idx)}>Alert Video <AlertVideoSvg/></div>}
                            {props.control?.permissions?.SUMMARY_HIDE_ALERT && !props.isReport && <div className="w-[100px] flex flex-row items-center">
                                <BinSvg color='#777676' className='cursor-pointer' onClick={() => setTargetDelete({...item,idx})} />
                            </div>}
                        </div>
                    )}
                </div>
                {targetDelete && <div className='fixed inset-0 flex justify-center items-center' style={{backdropFilter:'blur(5px)', zIndex: 999}}>
                    <div className='p-[24px] bg-[#363636B2] rounded-[8px] flex flex-col text-white relative max-h-[95%] min-w-[350px]' style={{border:'.5px solid #FFFFFF80',overflowY:'auto'}}>
                        <div className='flex flex-row items-center space-between w-full'>
                            <div className='font-bold'>Confirm Delete Alert?</div>          
                        </div>
                        <div className='absolute top-[24px] right-[24px] font-bold ml-20 cursor-pointer select-none' onClick={() => setTargetDelete(null)}>X</div>         

                        <div className='flex flex-row items-center space-between w-full mt-10'>
                            <div className='text-sm flex-1'>Name:</div>        
                            <div className='text-sm flex-2'>{targetDelete.name}</div>          
                        </div>
                        <div className='flex flex-row items-center space-between w-full mt-5'>
                            <div className='text-sm flex-1'>Alert Time:</div>       
                            <div className='text-sm flex-2'>{targetDelete?.time?new Date(targetDelete.time).toLocaleString():'-'}</div>          
                        </div>

                        <div onClick={handleDelete} className='button_blue w-full mt-10' style={{padding:'6px 0px'}}>
                            Delete
                        </div>
                    </div>
                </div>}
                {isSnapshot&&<AlertSnapshotPopup close={handleClosePopup} time={isSnapshot?.time} deviceId={isSnapshot?.deviceID}/>}
                {isAlertVideo&&<AlertVideoPopup close={handleCloseVideoPopup} data={isAlertVideo}/>}
        </div>
    )
}

export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  })(Component)