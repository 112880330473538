import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { connect } from 'react-redux';
import { LEFTRIGHT_PADDING } from "../config/style.config";
import PageSelector from '../components/PageSelector'
import LoadingPage from '../components/Loading'
//svg
import TitleLeftSvg from '../svgs/TitleLeftSvg'
import BinSvg from '../svgs/camera/BinSvg'
import ListViewSvg from '../svgs/camera/ListView';
import GridViewSvg from '../svgs/camera/GridView';
import EditIconSvg from "../svgs/camera/EditIcon";
import AlertPhotoSvg from '../svgs/camera/AlertPhotoSvg'
import AlertVideoSvg from '../svgs/camera/AlertVideoSvg'
import { createAICamera, deleteAICamera, editAICamera, fetchAICameras } from "../services/cameras.service";
import { Login } from "../actions/actions";
import WebrtcView from '../components/camera/WebrtcView';
import LatestSnapshot from '../components/camera/LatestSnapshot';
import LatestSnapshotPopup from '../components/camera/LatestSnapshotPopup';
import LatestVideoPopup from '../components/camera/LatestVideoPopup';
import NoPermission from './NoPermission';
import SmallSearchSvg from '../svgs/watchPage/SmallSearchSvg'
import { REFRESH_INTERVAL } from "../config";

function Screen(props){
    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    const [isLoading,setisLoading] = useState(false)

    //search
    const searchTxt =  searchParams.get('search');
    const handleSearch=(e)=>{setSearchParams({page:1,search:e.target.value})}
    //send of search

    //paging
    const page =  parseInt(searchParams.get('page'))||1;
    const limit=10;
    const [total,setTotal]=useState(0)
    //const [activeTotal,setactiveTotal]=useState(0)
    const totalPage = Math.ceil(total/limit)||1
    const handleSelectPage=(p)=>async ()=>{
        if(p > totalPage || p < 1) return;
        searchParams.delete('page')
        searchParams.set('page', p)
        setSearchParams(searchParams)
    }
    //end of paging
    const [isGridView,setisGridView]=useState(true)
    const [data,setData]=useState([])
    const sortBy={'data.name':1};
    const fetchData=async(p=page,l=limit,st=sortBy)=>{
        const filter={ projectId:props.control?.projectId,isDangerZone:{$ne:true} }
        if(searchTxt)filter['data.name']={"$regex":searchTxt, "$options":'i'}
        let result = await fetchAICameras(props.token.token,filter,{ skip: l*(p-1), limit:l , sort:st})
        if(result.errno===10001){
            props.dispatch(Login(false))
            return alert('Session Expired');
        }else if(result?.result){
            setData(result?.result);
            setTotal(result?.count||0)
            //setactiveTotal(result?.activeCount||0)
        }
    }


    //new record
    const [isCreating,setisCreating]=useState(false)
    const [createError,setCreateError]=useState(null)
    const handleStartCreate =()=>{setisCreating({data:{}})}
    const handleCancelCreate=()=>{setisCreating(null);setCreateError(null)}
    const handleCreateRecordInput=(field)=>(e)=>{
        let d= {...isCreating}
        d.data[field]=e.target.value
        if(field==='id')d.id=e.target.value
        setisCreating(d)
    }
    const handleCreateRecord=async()=>{
        if(!isCreating?.id){setCreateError('Serial Number cannot be empty.');return}
        if(!isCreating?.data?.name){setCreateError('Device Name cannot be empty.');return}
        if(isLoading)return
        setisLoading(true)
        let updateData = {
            id: isCreating?.id,
            projectId: props.control?.projectId,
            data:{
                id:isCreating?.id,
                name:isCreating?.data?.name,
            }
        }
        let result = await createAICamera(props.token?.token,updateData)
        if(result.errno){
            setCreateError(`Error: ${JSON.stringify(result?.message)}`)
        }else{
            handleCancelCreate()
            fetchData()
        }
        setisLoading(false);
    }
    //end of new record

     //edit record
     const [isUpdating,setisUpdating]=useState(false)
     const [updateError,setupdateError]=useState(null)
     const handleStartUpdating =(item,index)=>()=>{setisUpdating(JSON.parse(JSON.stringify(item)))}
     const handleCancelUpdate=()=>{setisUpdating(null);setupdateError(null)}
     const handleEditRecordInput=(field)=>(e)=>{
         let d= {...isUpdating}
         d.data[field]=e.target.value
         if(field==='id')d.id=e.target.value
         setisUpdating(d)
     }
     const handleUpdateDevice=async()=>{
         if(!isUpdating?.id){setupdateError('Serial Number cannot be empty.');return}
         if(!isUpdating?.data?.name){setupdateError('Device Name cannot be empty.');return}
         if(isLoading)return
         setisLoading(true)
         let updateData = {
             id: isUpdating?.id,
             'data.id':isUpdating?.id,
             'data.name':isUpdating?.data?.name,
             'data.longitude': isUpdating?.data?.longitude? Number(isUpdating?.data?.longitude): null,
             'data.latitude': isUpdating?.data?.latitude? Number(isUpdating?.data?.latitude): null
         }
         let result = await editAICamera(props.token?.token,isUpdating?._id,updateData)
         if(result.errno){
             setupdateError(`Error: ${JSON.stringify(result?.message)}`)
         }else{
             handleCancelUpdate()
             fetchData()
         }
         setisLoading(false);
     }
     //end of edit record

     //delete params
    const [deleteRecord,setdeleteRecord]=useState(null)
    const [deleteRecordError,setdeleteRecordError]=useState(null)
    const handleCancelDeleteRecord=()=>{setdeleteRecord(null)}
    const handleStartDeleteRecord=(item,index)=>()=>{ setdeleteRecord(item) }
    const handleDeleteRecord=async()=>{
        if(isLoading)return
        setisLoading(true)
        let result = await deleteAICamera(props.token?.token,deleteRecord?._id);
        if(result.errno){
            setdeleteRecordError(`Error: ${JSON.stringify(result?.message)}`)
        }else{
            handleCancelDeleteRecord()
            fetchData()
        }
        setisLoading(false);
    }
    //end of delete params

    //snapshot popup
    const [isSnapshot,setisSnapshot]=useState(null)
    const handleShowPopup=(item,index)=>()=>{
        setisSnapshot(item)
    }
    const handleClosePopup=()=>{setisSnapshot(null)}
    //end of snapshot popup

    //video popup
    const [isVideoPopup,setisVideoPopup]=useState(null)
    const handleShowVideoPopup=(item,index)=>()=>{
        setisVideoPopup(item)
    }
    const handleCloseVideoPopup=()=>{setisVideoPopup(null)}
    //end of video popup

    useEffect(() => {
        const timer = setTimeout(fetchData, 500)
        const interval = setInterval(fetchData,REFRESH_INTERVAL)
        return () => {
            clearTimeout(timer)
            clearInterval(interval)
        }
    },[searchParams])


    useEffect(()=>{
        searchParams.delete('page')
        searchParams.set('page', 1)
        setSearchParams(searchParams)
    },[props.control.projectId])

    if(!props.control?.permissions?.CAMERA_VIEW)
        return(<NoPermission/>)
    return(
        <div className='w-full h-full bg-[#0C0D14] flex flex flex-col relative overflow-auto text-[14px] text-white'>
            <div className='mb-[110px]'style={{...LEFTRIGHT_PADDING}}>
                <div className="flex flex-row mt-[40px]">
                    <TitleLeftSvg className='mr-[9px]'/>
                    <div className="text-[24px] text-white font-bold ">Live view</div>
                </div>

                {/*header/buttons/filter*/}
                <div className="flex flex-row items-center justify-between mt-[27px]">
                    <div className="flex flex-row items-center">
                        <div className="subpage_selected">Camera</div>
                        <div className="subpage_not_selected" onClick={() => navigate('/camera/alert')}>Alert</div>
                    </div>
                    <div className="flex flex-row items-center gap-2 flex-1 justify-end"> 
                        <div onClick={()=>{setisGridView(true)}} className="flex flex-row items-center cursor-pointer gap-[4px] select-none" style={{color:isGridView?'#63BDFF':'white'}}>
                            <GridViewSvg color={isGridView?'#63BDFF':'white'}/>
                            Grid View
                        </div>
                        |
                        <div onClick={()=>{setisGridView(false)}} className="flex flex-row items-center cursor-pointer gap-[4px] select-none" style={{color:!isGridView?'#63BDFF':'white'}}>
                            <ListViewSvg color={!isGridView?'#63BDFF':'white'}/>
                            List View
                        </div>
                        <div className="rounded-full flex flex-row items-center justify-between max-w-[225px] px-[16px] py-[2px]" style={{border:'.5px solid #FFFFFF80'}}>
                            <SmallSearchSvg className='mr-[12px]' />
                            <input  onChange={handleSearch} className="bg-transparent h-[21px] text-white min-w-0 " placeholder="Search Name"></input>
                        </div>
                        <div className="button_blue" onClick={handleStartCreate}>
                            + Camera
                        </div>
                    </div>
                </div>
                {/*end of header/buttons/filter*/}


                {/*main grid*/}
                {isGridView&&
                    <div className="mt-[25px] flex-wrap	flex flex-row gap-[16px] items-center justify-stretch mb-[64px]">
                        {data.map((item,index) => 
                            <div key={item?._id} className="inline-block py-[16px] px-[24px]" style={{border: '0.5px solid #FFFFFF80',width:'calc(50% - 8px)'}}>
                                <div className="flex flex-row items-center justify-between">
                                    <div className="flex flex-row items-center text-white">
                                        <TitleLeftSvg className='mr-[9px]'/>
                                        {item?.data?.name||'-'}  ({item?.id||'-'})
                                    </div>
                                    <div className="">
                                        {props.control.permissions?.CAMERA_EDIT&&<div className="button_blue" onClick={handleStartUpdating(item,index)}>
                                            <EditIconSvg width={12}/>
                                            Edit
                                        </div>}
                                    </div>
                                </div>
                                <div className="flex flex-row items-center gap-2 text-white mt-[4px] text-[10px] text-[#ddd] font-[300]">
                                    {/* <CalenderSvg /> */}
                                    Last Update: {new Date(item?.modifyDate).toLocaleString()}
                                </div>                   
                                <div className="relative flex flex-row items-center gap-[16px] mt-[32px] w-full">
                                    <div className="flex-1 p-[8px]" style={{border:'.5px solid #ffffff80'}}>
                                        <LatestSnapshot deviceId={item?.id}/>
                                    </div>
                                    <div className="flex-1 p-[8px] relative flex items-center justify-center" style={{border:'.5px solid #ffffff80'}}>
                                        {   item?.webrtcValidTil>Date.now()&&item?.data?.isConnected?
                                                <WebrtcView url={item?.webrtc}/>
                                            : <div className="w-full relative flex flex-col items-center justify-center text-[10px] text-white" style={{aspectRatio:1920/1080}} >
                                                Device offline
                                            </div>
                                        }
                                    </div>
                                </div>            
                            </div>
                        )}
                    </div>
                }
                {/*main list*/}
                {!isGridView&&
                    <div className="mt-[25px] flex-wrap	flex flex-row gap-[16px] items-center justify-stretch w-full mb-10" style={{borderTop:'.5px solid #ffffff40',borderBottom:'.5px solid #ffffff40'}}>
                        <div className="py-[32px] w-full">
                            <div className="flex flex-row text-[10px] font-bold w-full items-center">
                                <div className="flex-[1] table_header">Item</div>
                                <div className="flex-[2] table_header">Camera Id</div>
                                <div className="flex-[2] table_header">Camera Name</div>
                                <div className="flex-[2] table_header">Last Update</div>
                                <div className="flex-[2] table_header">Latest Snapshot</div>
                                <div className="flex-[2] table_header">Lastest Alert Video</div>
                                <div className="w-[100px] table_header"/>
                            </div>
                            {data.map((item, index) =>
                                <div key={item?._id||index} className="text-[14px] table_row" style={{overflowWrap: 'break-word',backgroundColor:index%2===1?'#FFFFFF0D':'transparent'}}>
                                    <div className="flex-[1] table_item">{index+1}</div>
                                    <div className="flex-[2] table_item">{item?.id||item?.data?.id}</div>
                                    <div className="flex-[2] table_item">{item?.data?.name}</div>
                                    <div className="flex-[2] table_item">{new Date(item?.modifyDate).toLocaleString()}</div>
                                    <div className="flex-[2] table_item flex flex-row items-center gap-[4px] cursor-pointer" onClick={handleShowPopup(item,index)}>Snapshot <AlertPhotoSvg/></div>
                                    <div className="flex-[2] table_item flex flex-row items-center gap-[4px] cursor-pointer" onClick={handleShowVideoPopup(item,index)}>Alert Video <AlertVideoSvg/></div>
                                    <div className="w-[100px] table_item flex flex-row items-center gap-[8px]">
                                        {props.control.permissions?.CAMERA_EDIT&&<div className='cursor-pointer bg-[#00202A] p-[8px] rounded-[7px]' onClick={handleStartUpdating(item,index)}><EditIconSvg/></div>}
                                        {props.control.permissions?.CAMERA_DELETE&&<div className="cursor-pointer" onClick={handleStartDeleteRecord(item,index)}><BinSvg className='cursor-pointer' color='#ffffff40'/></div>}
                                    </div>
                                </div>
                            )}
                        </div>
                        {/*end of data list*/}
                    </div>
                }
                {/*end of main list/grid*/}

                <PageSelector
                    handleSelectPage={handleSelectPage}
                    page={page}
                    total={total}
                    totalPage={totalPage}
                />
            </div>


            {/*create record popup*/}
            {isCreating&&<div className='fixed inset-0 flex justify-center items-center' style={{backdropFilter:'blur(5px)'}}>
                <div className='p-[24px] bg-[#363636B2] rounded-[8px] flex flex-col text-white relative max-h-[95%] min-w-[300px]' style={{border:'.5px solid #FFFFFF80',overflowY:'auto'}}>
                    <div className='flex flex-row items-center space-between w-full'>
                        <div className='font-bold'>Add AI Camera</div>          
                    </div>
                    <div className='absolute top-[24px] right-[24px] font-bold ml-20 cursor-pointer select-none' onClick={handleCancelCreate}>X</div>         

                    <div className='font-bold text-sm mt-10'>Serial Number*</div>
                    <input value={(isCreating?.id)||""} onChange={handleCreateRecordInput('id')} placeholder='serial number' className='bg-transparent rounded-[99px] text-sm px-[16px] py-[6px] mt-1' style={{border:'.5px solid white'}}/>

                    <div className='font-bold text-sm mt-5'>Device Name*</div>
                    <input value={(isCreating?.data?.name)||""} onChange={handleCreateRecordInput('name')} placeholder='deivice name' className='bg-transparent rounded-[99px] text-sm px-[16px] py-[6px] mt-1' style={{border:'.5px solid white'}}/>

                    <div className='font-bold text-sm mt-5'>Longitude</div>
                    <input value={(isCreating?.data?.longitude)||""} onChange={handleCreateRecordInput('longitude')} placeholder='longitude' className='bg-transparent rounded-[99px] text-sm px-[16px] py-[6px] mt-1' style={{border:'.5px solid white'}}/>
                    <div className='font-bold text-sm mt-5'>Latitude</div>
                    <input value={(isCreating?.data?.latitude)||""} onChange={handleCreateRecordInput('latitude')} placeholder='latitude' className='bg-transparent rounded-[99px] text-sm px-[16px] py-[6px] mt-1' style={{border:'.5px solid white'}}/>

                    <div className='w-full text-[red] text-xs'>{createError}</div>

                    <div onClick={handleCreateRecord} className='w-full text-[#63BDFF] bg-[#00202A] mt-5 py-[6px] flex flex-row item-center justify-center rounded-[99px] hover:bg-[#10303A] hover:font-bold select-none cursor-pointer'>
                        Add AI Camera
                    </div>
                    <div onClick={handleCancelCreate} className='w-full text-[#ffffffa0] bg-[#ffffff40] mt-2 py-[6px] flex flex-row item-center justify-center rounded-[99px] hover:bg-[#ffffff60] hover:font-bold select-none cursor-pointer'>
                        Cancel
                    </div>
                </div>
            </div>}
            {/*end of create record popup*/}

            {/*edit record popup*/}
            {isUpdating&&<div className='fixed inset-0 flex justify-center items-center' style={{backdropFilter:'blur(5px)'}}>
                <div className='p-[24px] bg-[#363636B2] rounded-[8px] flex flex-col text-white relative max-h-[95%] min-w-[300px]' style={{border:'.5px solid #FFFFFF80',overflowY:'auto'}}>
                    <div className='flex flex-row items-center space-between w-full'>
                        <div className='font-bold'>Edit AI Camera</div>          
                    </div>
                    <div className='absolute top-[24px] right-[24px] font-bold ml-20 cursor-pointer select-none' onClick={handleCancelUpdate}>X</div>         

                    <div className='font-bold text-sm mt-10'>Serial Number*</div>
                    <input value={(isUpdating?.id)||""} onChange={handleEditRecordInput('id')} placeholder='serial number' className='bg-transparent rounded-[99px] text-sm px-[16px] py-[6px] mt-1' style={{border:'.5px solid white'}}/>

                    <div className='font-bold text-sm mt-5'>Device Name*</div>
                    <input value={(isUpdating?.data?.name)||""} onChange={handleEditRecordInput('name')} placeholder='deivice name' className='bg-transparent rounded-[99px] text-sm px-[16px] py-[6px] mt-1' style={{border:'.5px solid white'}}/>

                    <div className='font-bold text-sm mt-5'>Longitude</div>
                    <input value={(isUpdating?.data?.longitude)||""} onChange={handleEditRecordInput('longitude')} placeholder='longitude' className='bg-transparent rounded-[99px] text-sm px-[16px] py-[6px] mt-1' style={{border:'.5px solid white'}}/>
                    <div className='font-bold text-sm mt-5'>Latitude</div>
                    <input value={(isUpdating?.data?.latitude)||""} onChange={handleEditRecordInput('latitude')} placeholder='latitude' className='bg-transparent rounded-[99px] text-sm px-[16px] py-[6px] mt-1' style={{border:'.5px solid white'}}/>

                    <div className='w-full text-[red] text-xs'>{updateError}</div>

                    <div onClick={handleUpdateDevice} className='w-full text-[#63BDFF] bg-[#00202A] mt-5 py-[6px] flex flex-row item-center justify-center rounded-[99px] hover:bg-[#10303A] hover:font-bold select-none cursor-pointer'>
                        Update
                    </div>
                    <div onClick={handleCancelUpdate} className='w-full text-[#ffffffa0] bg-[#ffffff40] mt-2 py-[6px] flex flex-row item-center justify-center rounded-[99px] hover:bg-[#ffffff60] hover:font-bold select-none cursor-pointer'>
                        Cancel
                    </div>
                </div>
            </div>}
            {/*end of edit record popup*/}

            {/*delete record popup*/}
            {deleteRecord&&<div className='fixed inset-0 flex justify-center items-center' style={{backdropFilter:'blur(5px)'}}>
                <div className='p-[24px] bg-[#363636B2] rounded-[8px] flex flex-col text-white relative' style={{border:'.5px solid #FFFFFF80'}}>
                    <div className='flex flex-row items-center space-between w-full pr-[64px]'>
                        <div className='font-bold'>Confirm Delete?</div>          
                    </div>
                    <div className='absolute top-[24px] right-[24px] font-bold ml-20 cursor-pointer select-none' onClick={handleCancelDeleteRecord}>X</div>         

                    <div className='flex flex-row items-center space-between w-full mt-10'>
                        <div className='text-sm'>Device Name: {deleteRecord?.data?.name||deleteRecord?.id}</div>          
                    </div>

                    <div className='w-full text-[red] text-xs'>{deleteRecordError}</div>

                    <div onClick={handleCancelDeleteRecord} className='w-full text-[white] bg-[#ffffff20] mt-10 py-[6px] flex flex-row item-center justify-center rounded-[99px] hover:bg-[#ffffff30] hover:font-bold select-none cursor-pointer'>
                        Cancel
                    </div>
                    <div onClick={handleDeleteRecord} className='w-full text-[#FF969C] bg-[#FF969C20] mt-2 py-[6px] flex flex-row item-center justify-center rounded-[99px] hover:bg-[#FF969C30] hover:font-bold select-none cursor-pointer'>
                        Delete
                    </div>
                </div>
            </div>}
            {/*end of delete record popup*/}
            

            {/*snapshot popup*/}
            {isSnapshot&&<LatestSnapshotPopup close={handleClosePopup} deviceId={isSnapshot?.id}/>}
            {isVideoPopup&&<LatestVideoPopup close={handleCloseVideoPopup} deviceId={isVideoPopup?.id}/>}
            {/*end of snapshot popup*/}

            {isLoading&&<LoadingPage/>}
            
        </div>
    )
}

export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  })(Screen)